import { FactoryProvider } from '@angular/core';
import { userInitializerProvider } from './user.provider';
import { historyInitializerProvider } from './history.provider';
import { talkJsInitializerProvider } from './talkjs.provider';
import { onboardingSurveyInitializerProvider } from './onboarding-survey.provider';

export const appInitializer: FactoryProvider[] = [
  userInitializerProvider,
  onboardingSurveyInitializerProvider,
  historyInitializerProvider,
  talkJsInitializerProvider,
];
