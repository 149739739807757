import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private errorHandler: ErrorHandler, private router: Router) {}

  intercept<T, K>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<K>> {
    return next.handle(request).pipe(
      catchError((error: unknown) => {
        this.errorHandler.handleError(error);
        if (error instanceof HttpErrorResponse) {
          if (error.status === 403) {
            this.router.navigate(['/forbidden']);
          } else if (error.status === 404) {
            this.router.navigate(['/not-found']);
          }
        }
        return throwError(() => error);
      }),
    );
  }
}
