@if ((loadingState$ | async).isLoading) {
  <div class="backdrop" [ngClass]="{white: color === 'white', black: color === 'black'}">
    <div class="loading-content">
      <ess-loading-spinner [color]="color"></ess-loading-spinner>
      <p class="sans">
        {{ message }}
        <span>this request could take a few seconds </span>
      </p>
    </div>
  </div>
}
