import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { environment } from '@environment';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { routes } from './app.routes';
import { appInitializer } from './core/providers/app-initializer.provider';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Angulartics2Module } from 'angulartics2';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthModule } from './core/auth/auth.module';
import { ErrorsModule } from './core/errors/errors.module';
import { provideTransloco } from '@jsverse/transloco';
import { translocoConfig } from '@app/transloco.config';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }),
      withRouterConfig({ onSameUrlNavigation: 'reload' }),
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
      Angulartics2Module.forRoot(),
      MatSnackBarModule,
      MatDialogModule,
      AuthModule,
      ErrorsModule,
    ),
    { provide: 'googleTagManagerId', useValue: environment.gtmApiKey },
    { provide: 'env', useValue: environment },
    provideTransloco(translocoConfig),
    provideTranslocoMessageformat(),
    appInitializer,
  ],
};
