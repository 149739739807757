import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { OnboardingSurveyDomainService } from '@app/domain/onboarding-survey-domain.service';
import { of } from 'rxjs';

function initializeOnboardingSurvey(onboardingSurveyDomainService: OnboardingSurveyDomainService) {
  return () => {
    onboardingSurveyDomainService.initOnboardingCheck();
    return of(true);
  };
}

export const onboardingSurveyInitializerProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializeOnboardingSurvey,
  deps: [OnboardingSurveyDomainService],
  multi: true,
};
