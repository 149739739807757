import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserDomainService } from '@app/domain/user-domain.service';
import { AuthStorageService } from '@ess-front/shared';
import { take, Observable, of, switchMap, catchError, filter } from 'rxjs';

export const onboardingSurveyCompletedGuard = (completed: boolean) => {
  return (): Observable<boolean> => {
    const authStorageService = inject(AuthStorageService);
    const userDomainService = inject(UserDomainService);
    const router = inject(Router);

    if (authStorageService.getToken()) {
      return userDomainService.getCurrentUser$().pipe(
        filter(Boolean),
        switchMap(user => {
          if (user.isOnboardingDone !== completed) {
            router.navigate(['/members-area']);
            return of(false);
          } else return of(true);
        }),
        take(1),
        catchError((err: unknown) => onError$(err, router)),
      );
    } else {
      router.navigate(['/members-area/login']);
      return of(false);
    }
  };
};

function onError$(error: unknown, router: Router): Observable<boolean> {
  router.navigate(['/members-area/login']);
  throw error;
}
