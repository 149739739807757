import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../auth-service/auth.service';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthService;
  private errorHandler: ErrorHandler;
  private authenticatedRoutes = null;

  constructor(
    private injector: Injector,
    private router: Router,
  ) {}

  intercept<T, K>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<K>> {
    this.authService = this.injector.get(AuthService);
    this.errorHandler = this.injector.get(ErrorHandler);
    if (this.router.url.includes(this.authenticatedRoutes) && this.authService.getToken()) {
      request = request.clone({
        setHeaders: {
          authorization: this.authService.getToken() ? `token ${this.authService.getToken()}` : '',
        },
      });
    }

    return next.handle(request).pipe(
      // try once again and then catch error
      retry(1),
      catchError((error: unknown) => {
        this.errorHandler.handleError(error);
        // Using the magic link when the server responses with an unauthorized error.
        if (
          error instanceof HttpErrorResponse &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          !(window as any).ReactNativeWebView &&
          error.status === 401 &&
          !this.router.url.includes('magic-link')
        ) {
          localStorage.setItem('ess_magic_url', location.href.split('?')[0]);
          this.router.navigate(['/magic-link']);
        }
        return throwError(() => error);
      }),
    );
  }
}
